@tailwind base;
@tailwind components;
@tailwind utilities;
@switch-bg: red;

* {
  outline: none;
}

#webpack-dev-server-client-overlay {
  display: none;
}

:root,
[data-theme] {
  background: rgb(243 244 246);
}

.ant-table
  .ant-table-middle
  .ant-table-fixed-header
  .ant-table-fixed-column
  .ant-table-scroll-horizontal
  .ant-table-has-fix-left {
  margin: 0 !important;
}

.ant-switch.ant-switch-checked {
  background: #1677ff !important;
}

.ant-switch:hover:not(.ant-switch-disabled) {
  background: rgba(0, 0, 0, 0.45);
}

.ant-switch{
  background: rgba(0, 0, 0, 0.25);
}

.table-row-green {
  background-color: green !important;
}
